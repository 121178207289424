import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import moment from "moment";
import reportService from "./reports-service";
import exportLimit from "../../common/export-limit.vue";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      sDate: "",
      eDate: "",
      startDate: false,
      endDate: false,
      selectedProject: "",
      projectList: [],
      disableAll: true,
      disableSpecific: true,
      selectedBusiness: "",
      businessData: [],
      dateSelected: false,
      runDisabled: true,
      specificDialog: false,
      partClassSearchValid: false,
      page: 1,
      totalPages: 0,
      showBusiness: false,
      completedBTOData: [],
      excelName: "",
      totalRecords: 0,
      exportDisable: true,
      export50kData: false,
      exportDialog: false,
      showData: false,
      showStartDate: true,
      showEndDate: true,
      hideAll: false,
      hideSpecific: false,
      showPartNum: false,
      apiObj: {
        pass: 0,
        user_id: 0,
        lcsdate: "",
        lcedate: "",
        proj_id: 0,
        part_num: "",
        bu_id: 0,
        PageNumber: 0,
        export: 0,
        guid: "",
      },
      headerBTO: [
        { text: "SO", value: "SO", class: "primary customwhite--text", width: "8%" },
        { text: "BTO", value: "BTO", class: "primary customwhite--text", width: "8%" },
        { text: "Name", value: "Name", class: "primary customwhite--text", width: "8%" },
        { text: "Kit Name", value: "KitName", class: "primary customwhite--text", width: "8%" },
        { text: "Part Number", value: "PartNo", class: "primary customwhite--text", width: "8%" },
        { text: "ASSET/BCN", value: "ASSET/BCN", class: "primary customwhite--text", width: "8%" },
        { text: "Serial/ICCID", value: "Serial/ICCID", class: "primary customwhite--text", width: "8%" },
        { text: "Warehouse", value: "Warehouse", class: "primary customwhite--text", width: "8%" },
        { text: "Date Issued", value: "Date Issued", class: "primary customwhite--text", width: "12%" },
        { text: "Date Completed", value: "Date Completed", class: "primary customwhite--text", width: "12%" },
        { text: "Date Shipped", value: "Date Shipped", class: "primary customwhite--text", width: "12%" },
      ],
      json_fields: {
        SO: "SO",
        BTO: "BTO",
        Name: "Name",
        "Kit Name": "KitName",
        "Part Number": "PartNo",
        "ASSET/BCN": "ASSET/BCN",
        "Serial/ICCID": "Serial/ICCID",
        IMEI: "IMEI",
        ICCID: "ICCID",
        "LAN Adapter": "LAN Adapter",
        "LAN Wi-Fi Adapter": "LAN Wi-Fi Adapter",
        "BT Adapter": "BT Adapter",
        "Mobile Adapter": "Mobile Adapter",
        Warehouse: "Warehouse",
        "Date Issued": "Date Issued",
        "Date Completed": "Date Completed",
        "Date Shipped": "Date Shipped",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.sDate ? moment(this.sDate).format("MM/DD/YYYY") : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.eDate ? moment(this.eDate).format("MM/DD/YYYY") : moment(new Date()).format("MM/DD/YYYY");
    },
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.showStartDate = false;
      this.showEndDate = false;
      this.$nextTick(() => {
        this.showStartDate = true;
        this.sDate = null;
        this.showEndDate = true;
        this.eDate = null;
      });
      this.selectedProject = "";
      this.projectList = [];
      this.disableAll = true;
      this.disableSpecific = true;
      this.selectedBusiness = "";
      this.businessData = [];
      this.dateSelected = false;
      this.runDisabled = true;
      this.specificDialog = false;
      this.partClassSearchValid = false;
      this.page = 1;
      this.totalPages = 0;
      this.showBusiness = false;
      this.completedBTOData = [];
      this.excelName = "";
      this.totalRecords = 0;
      this.exportDisable = true;
      this.export50kData = false;
      this.exportDialog = false;
      this.showData = false;
      this.hideAll = false;
      this.hideSpecific = false;
      this.showPartNum = false;
      this.apiObj = {
        pass: 0,
        user_id: 0,
        lcsdate: "",
        lcedate: "",
        proj_id: 0,
        part_num: "",
        bu_id: 0,
        PageNumber: 0,
        export: 0,
        guid: "",
      };
    },
    // set end Date
    setEndDate() {
      let minEndDate = new Date(this.sDate);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000).toISOString().substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0).toISOString().substr(0, 10);
    },
    //On click continue
    async onContinue() {
      !this.sDate ? (this.sDate = new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10)) : "";
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.pass = 0;
      this.apiObj.user_id = parseInt(this.userId);
      let showMessage = false;
      let projData = await reportService.completedBTOReport("post", this.apiObj, showMessage);
      if (projData.message !== "NA") {
        this.projectList = projData;
        this.dateSelected = true;
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Data Available change the Start Date",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.dateSelected = false;
      }
    },
    //Project Selected
    onChangeProject() {
      this.disableAll = false;
      this.disableSpecific = false;
      this.runDisabled = true;
      this.showBusiness = false;
      this.completedBTOData = [];
      this.totalRecords = 0;
      this.totalPages = 0;
      this.exportDisable = true;
      this.showData = false;
      this.hideAll = false;
      this.hideSpecific = false;
      this.showPartNum = true;
      // this.allPartNumber();
    },
    //All Part Clicked
    async allPartNumber() {
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.pass = 1;
      this.apiObj.user_id = parseInt(this.userId);
      this.apiObj.proj_id = parseInt(this.selectedProject);
      let showMessage = false;
      let busData = await reportService.completedBTOReport("post", this.apiObj, showMessage);
      if (busData.message !== "NA") {
        this.businessData = busData;
        this.showBusiness = true;
        this.runDisabled = true;
        this.showData = false;
        this.hideAll = false;
        this.hideSpecific = true;
      }
    },
    //Run Report
    async runReport(page) {
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.pass = 3;
      this.apiObj.user_id = parseInt(this.userId);
      this.apiObj.proj_id = parseInt(this.selectedProject);
      this.apiObj.bu_id = parseInt(this.selectedBusiness);
      this.apiObj.PageNumber = page;
      this.apiObj.export = 0;
      let showMessage = false;
      let completedBTOData = await reportService.completedBTOReport("post", this.apiObj, showMessage);
      if (completedBTOData.message !== "NA") {
        if (completedBTOData.Resultset.length > 0) {
          const newArr = completedBTOData.Resultset.map((obj) => {
            return {
              ...obj,
              "Date Issued": obj["Date Issued"] !== undefined ? moment(obj["Date Issued"]).format("MM/DD/YYYY") : "",
              "Date Completed": obj["Date Completed"] !== undefined ? moment(obj["Date Completed"]).format("MM/DD/YYYY") : "",
              "Date Shipped": obj["Date Shipped"] !== undefined ? moment(obj["Date Shipped"]).format("MM/DD/YYYY") : "",
            };
          });
          this.completedBTOData = newArr;
          this.totalRecords = completedBTOData.TotalRecords;
          this.exportDisable = false;
          this.totalPages = completedBTOData.TotalPages;
          this.showData = true;
        } else {
          this.completedBTOData = [];
          this.totalRecords = 0;
          this.totalPages = 0;
          this.exportDisable = true;
          this.showData = false;
          let Alert = {
            type: "error",
            isShow: true,
            message: "No records based on the selected criteria",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },
    //Export Data using component
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    //To export the Excel file
    async exportExcel() {
      if (this.totalRecords <= 15000 || this.export50kData) {
        this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
        this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
        this.apiObj.pass = 3;
        this.apiObj.user_id = parseInt(this.userId);
        this.apiObj.proj_id = parseInt(this.selectedProject);
        this.apiObj.bu_id = parseInt(this.selectedBusiness);
        this.apiObj.part_num = 1;
        this.apiObj.PageNumber = 1;
        this.apiObj.export = 1;
        this.apiObj.guid = 1;
        this.excelName =
          "Completed_BTO_Report_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
        const response = await this.axios.post("/rt/completed_bto_report", this.apiObj);
        let responseData = JSON.parse(response.data.body.message).Resultset;
        const newArr = responseData.map((obj) => {
          return {
            ...obj,
            "Date Issued": obj["Date Issued"] !== undefined ? moment(obj["Date Issued"]).format("MM/DD/YYYY") : "",
            "Date Completed": obj["Date Completed"] !== undefined ? moment(obj["Date Completed"]).format("MM/DD/YYYY") : "",
            "Date Shipped": obj["Date Shipped"] !== undefined ? moment(obj["Date Shipped"]).format("MM/DD/YYYY") : "",
          };
        });
        this.export50kData = false;
        return newArr;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //End the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
